import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, history } from 'react-router';
import { atom, useAtom } from 'jotai';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardTitle,
    Badge,
    CustomInput,
    CardText,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Container,
    FormGroup,
    Label,
    InputGroup,
    Input,
    InputGroupAddon,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Form,
    FormFeedback,
    CardFooter,
} from 'reactstrap';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import cardAccountDetailsOutline from '@iconify/icons-mdi/card-account-details-outline';
import messageBadgeOutline from '@iconify/icons-mdi/message-badge-outline';
import accountSettingsOutline from '@iconify/icons-mdi/account-settings-outline';
import pencilIcon from '@iconify/icons-mdi/pencil';
import contentSave from '@iconify/icons-mdi/content-save';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import { SrxApi } from '../api/SrxApi';
import { UserContext } from '../contexts/UserContext';
import checkCircle from '@iconify/icons-mdi/check-circle';
import checkBold from '@iconify/icons-mdi/check-bold';
import { confirm } from '../utility-components/ConfirmModal';
import Draggable from 'react-draggable';
import cloneDeep from 'lodash.clonedeep';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
    arrayMove,
    arraySwap,
    rectSwappingStrategy,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { highCostAtomSortOrder } from '../jotai-atoms/AlertAtoms';
import Swal from 'sweetalert2';
import {
    highCostAtomUserSettings,
    highCostAlertsAtomUserSettings,
    clarificationsAtomUserSettings,
    priorAuthAtomUserSettings,
    nonCoveredAtomUserSettings,
    therapeuticIntAtomUserSettings,
    admissionMedsAtomUserSettings,
    refillTooSooAtomUserSettings,
    pumpPickupAtomUserSettings,
    narcoticsRefillAtomUserSettings,
} from '../jotai-atoms/AlertAtoms';

import { GridContextProvider, GridDropZone, GridItem, swap, move } from 'react-grid-dnd';
import { WidgetsData } from '../data/WidgetsData';
import { includes } from 'lodash';

const UserSettings = ({ userData, toggle, users, setUsers, source }) => {
    console.log(userData);
    const { user, logoutUser } = useContext(UserContext);
    const [lockSettings, setLockSettings] = useState(false);

    const [highCostUserSettings, setHighCostUserSettings] = useAtom(highCostAtomUserSettings);
    const [highCostAlertsUserSettings, setHighCostAlertsUserSettings] = useAtom(highCostAlertsAtomUserSettings);
    const [nonCoveredUserSettings, setNonCoveredUserSettings] = useAtom(nonCoveredAtomUserSettings);
    const [clarificationsUserSettings, setClarificationsUserSettings] = useAtom(clarificationsAtomUserSettings);
    const [priorAuthUserSettings, setPriorAuthUserSettings] = useAtom(priorAuthAtomUserSettings);
    const [therapeuticIntUserSettings, setTherapeuticIntUserSettings] = useAtom(therapeuticIntAtomUserSettings);
    const [admissionMedsUserSettings, setAdmissionMedsUserSettings] = useAtom(admissionMedsAtomUserSettings);
    const [refillTooSooUserSettings, setRefillTooSoonUserSettings] = useAtom(refillTooSooAtomUserSettings);
    const [pumpPickupUserSettings, setPumpPickupUserSettings] = useAtom(pumpPickupAtomUserSettings);
    const [narcoticsRefillUserSettings, setNarcoticsRefillUserSettings] = useAtom(narcoticsRefillAtomUserSettings);
    let history = useHistory();

    useEffect(() => {
        if (userData.userSettings) {
            setLockSettings(
                userData.userSettings[userData?.userSettings?.findIndex((s) => s.settingName === 'allowToSetSettings')]
                    .value === 'true'
                    ? false
                    : true
            );
        }
    }, [userData]);

    const getShowAlertSetting = (widgetName) => {
        if (!userData.userSettings) {
            return;
        }
        //if setting is set to show: true return hidden false
        if (
            userData.userSettings[userData?.userSettings?.findIndex((s) => s.settingName === widgetName)]?.value ===
            'true'
        ) {
            return false;
        } else {
            return true;
        }
    };

    const getSortAlertSetting = (widgetName) => {
        if (!userData.userSettings) {
            return;
        }
        return parseInt(
            userData.userSettings[userData?.userSettings?.findIndex((s) => s.settingName === widgetName)]?.value
        );
    };

    let alertTables = [
        {
            index: getSortAlertSetting('widget_highCost_SortOrder'),
            id: 1,
            name: 'AUTHORIZATION',
            value: 'highCost',
            hidden: getShowAlertSetting('widget_highCost_Show'),
        },
        {
            index: getSortAlertSetting('widget_openBatch_SortOrder'),
            id: 2,
            name: 'CLARIFICATIONS',
            value: 'openBatch',
            hidden: getShowAlertSetting('widget_openBatch_Show'),
        },
        {
            index: getSortAlertSetting('widget_nonCovered_SortOrder'),
            id: 3,
            name: 'NON-COVERED',
            value: 'nonCovered',
            hidden: getShowAlertSetting('widget_nonCovered_Show'),
        },
        {
            index: getSortAlertSetting('widget_priorAuth_SortOrder'),
            id: 4,
            name: 'PRIOR AUTHORIZATION MEDICATION',
            value: 'priorAuth',
            hidden: getShowAlertSetting('widget_priorAuth_Show'),
        },
        {
            index: getSortAlertSetting('widget_theraInt_SortOrder'),
            id: 5,
            name: 'THERAPEUTIC INTERCHANGED',
            value: 'theraInt',
            hidden: getShowAlertSetting('widget_theraInt_Show'),
        },
        {
            index: getSortAlertSetting('widget_admissionMeds_SortOrder'),
            id: 6,
            name: 'ADMISSION / MEDICATION REVIEW',
            value: 'admissionMeds',
            hidden: getShowAlertSetting('widget_admissionMeds_Show'),
        },
        {
            index: getSortAlertSetting('widget_highCostAlerts_SortOrder'),
            id: 7,
            name: 'HIGH COST Alerts',
            value: 'highCostAlerts',
            hidden: getShowAlertSetting('widget_highCostAlerts_Show'),
        },
        {
            index: getSortAlertSetting('widget_refillTooSoon_SortOrder'),
            id: 8,
            name: 'REFILL TOO SOON',
            value: 'refillTooSoon',
            hidden: getShowAlertSetting('widget_refillTooSoon_Show'),
        },
        {
            index: getSortAlertSetting('widget_pumpPickup_SortOrder'),
            id: 9,
            name: 'PUMP PICKUP',
            value: 'pumpPickup',
            hidden: getShowAlertSetting('widget_pumpPickup_Show'),
        },
        {
            index: getSortAlertSetting('widget_narcoticsRefill_SortOrder'),
            id: 9,
            name: 'NARCOTICS REFILL',
            value: 'narcoticsRefill',
            hidden: getShowAlertSetting('widget_narcoticsRefill_Show'),
        },
        // {
        // id: 8,
        //     name: ' patMissing',
        //     value: 'patMissing',
        //     hidden: highCostAtomUserSettings.hidden
        // },
    ];

    const [localData, setLocalData] = useState(alertTables);

    console.log(localData);
    const handleSwitches = (e, item) => {
        let tempData = [...localData];
        tempData[tempData.findIndex((a) => a.value === item.value)].hidden =
            !tempData[tempData.findIndex((a) => a.value === item.value)].hidden;
        setLocalData([...tempData]);
    };
    const handleLock = () => {
        setLockSettings(!lockSettings);
    };

    const SortableUser = ({ user }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: user.id });
        const style = {
            transition,
            transform: CSS.Transform.toString(transform),
        };

        return (
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
                className="user bg-light mb-2 p-1 text-center rounded">
                <div>{user.name}</div>
            </div>
        );
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id === over.id) {
            return;
        }
        setLocalData((localData) => {
            const oldIndex = localData.findIndex((user) => user.id === active.id);
            const newIndex = localData.findIndex((user) => user.id === over.id);
            return arrayMove(localData, oldIndex, newIndex);
        });
    };

    const saveAlertConfig = () => {
        Swal.fire({
            title: `Set settings for ${userData.displayName}?`,
            text: lockSettings ? `you set this user's setting to be locked; they won't be able to change it` : null,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Proceed',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                let settingValues = {};
                const setSetting = (alert, index) => {
                    let widgetSort = `widget_${alert.value}_SortOrder`;
                    let widgetShow = `widget_${alert.value}_Show`;

                    settingValues = {
                        ...settingValues,
                        [widgetSort]: index.toString(),
                        [widgetShow]: `${!alert.hidden}`,
                        allowToSetSettings: `${!lockSettings}`,
                    };
                };

                localData.forEach((alert, index) => {
                    setSetting(alert, index);
                });
                let userId = source === 'facility' ? userData.id : userData.userId;
                let userSettingData = {
                    UserId: userId,
                    settingValues,
                };
                SrxApi.post('/usersetting', userSettingData)
                    .then((res) => {
                        let tempUsers = [...users];
                        tempUsers[tempUsers.findIndex((u) => u.userId === userData.userId)] = userData;
                        setUsers(tempUsers);
                        toast.success(`successfully saved settings for ${userData.displayName}`);
                        toggle();
                    })
                    .catch((err) => toast.error('Error: ' + err.message));
            } else if (result.isDenied) {
                return;
            }
        });
    };
    alertTables = alertTables.sort((a, b) => (a.index < b.index ? -1 : b.index < a.index ? 1 : 0));
    return (
        <>
            <Container
                className="mt-5 mb-2 w-100 px-5 py-3 border border-light shadow-sm"
                style={{ borderRadius: '14px', minWidth: 'max-content' }}>
                <div className="d-flex justify-content-center">
                    <h3 className="text-center">{userData.displayName}'s alert settings</h3>
                    <div className="bg-light rounded ml-3 p-1">
                        <span>Lock user's settings</span>
                        <CustomInput
                            type="switch"
                            id={'exampleCustomSwitch'}
                            // name={item.value}
                            value={lockSettings}
                            label={lockSettings ? 'locked' : 'unlocked'}
                            checked={lockSettings}
                            className="text-center"
                            onChange={(e) => handleLock()}
                        />
                    </div>
                </div>
                <hr />
                <Row>
                    <Col xs={6}>
                        <div>
                            <Form>
                                <FormGroup>
                                    <div>
                                        {alertTables?.map((item, index) => (
                                            <div className="mb-3">
                                                <CustomInput
                                                    key={Math.random()}
                                                    type="switch"
                                                    id={'exampleCustomSwitch' + index}
                                                    name={item.value}
                                                    value={localData.find((i) => i.value === item.value)}
                                                    label={item.name}
                                                    checked={!localData.find((i) => i.value === item.value)?.hidden}
                                                    className=""
                                                    onChange={(e) => handleSwitches(e, item)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={6} className="text-right float-right">
                        <div>
                            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                <SortableContext
                                    items={localData}
                                    strategy={rectSortingStrategy}
                                    reorderItems={arraySwap}
                                    getNewIndex={({ id, items, activeIndex, overIndex }) =>
                                        arraySwap(items, activeIndex, overIndex).indexOf(id)
                                    }>
                                    <div className="align-right">
                                        {localData
                                            .filter((a) => !a.hidden)
                                            .map((item, index) => (
                                                <SortableUser key={index} user={item} />
                                            ))}
                                    </div>
                                </SortableContext>
                            </DndContext>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="text-center mx-auto mt-5">
                        <Button onClick={saveAlertConfig}>Save Changes</Button>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default UserSettings;
